import React from "react";
import Routes from "./Routes";
import "./styles/App.css"


function App() {
  return <Routes />;
}

export default App;
