import React from "react";
import { Link } from "react-router-dom";
import { Button, Img, Text, Line } from "components";
import { connect } from "react-redux";
import { store } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
const Header = (props) => {
  // const isLoggedIn = !!localStorage.getItem("username"); // Check if username is present in local storage
  // const userName = localStorage.getItem("username");
  const { isOpen, setIsOpen } = props;
  const navigate = useNavigate();

  const logoutUser = () => {
    return {
      type: "LOGOUT_USER",
    };
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const Logout = () => {
    store.dispatch(logoutUser());
    localStorage.removeItem("reduxState");

    deleteCookie("token");
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Logout Successful",
      showConfirmButton: false,
      timer: 1700,
      iconColor: "rgb(232, 13, 113)", // Set the desired RGB color
    }).then(() => {
      return (window.location.href = "/");
    });
  };

  const handleCity = () => {
    window.scrollTo(0, 0);
    sessionStorage.clear();
    navigate("/homepage");
  };
  const displayName = props.userName ? props.userName.split("@")[0] : "";
  return (
    <>
      <div
        style={{ position: "fixed", zIndex: 9999999999 }}
        className="w-[100%] pt-[18px]  "
      >
        <div className=" flex  justify-center bg-black-900 w-[100%]  py-2 ">
          <header style={{ width: "94%" }} className={props.className}>
            <div className="  flex sm:flex-row flex-row gap-[33px] items-center justify-start w-auto sm:w-[100%]">
              <div className="flex flex-row gap-[35px] items-start justify-start w-auto">
                <div className="mb-[10px] ">
                  <Img
                    onClick={() => handleCity()}
                    className="h-[50px] sm:w-[100%]  sm:h-[35px] object-cover  md:w-full cursor-pointer ms-[9px] "
                    src="/images/img_logo1.svg"
                    alt="logoOne"
                  />
                </div>

                {/* <div className="flex flex-row gap-[5px] items-end justify-start w-auto">
              <Text
                className="text-base text-white-A700 w-auto"
                size="txtInterRegular16"
              >
                EN
              </Text>
              
            </div> */}
                {/* <div className="flex flex-row gap-[5px] items-end justify-start w-auto">
              <Text
                className="text-base text-white-A700 w-auto"
                size="txtInterRegular16"
              >
                $
              </Text>
             
            </div> */}
              </div>
              {/* <div className="flex flex-row gap-[42px] items-center justify-center w-auto">
            <div className="flex flex-row gap-[5px] items-center justify-center w-auto">
              <Img
                className="h-[18px] w-[18px]"
                src="/images/img_location.svg"
                alt="location"
              />
              <Text
                className="text-base text-white-A700 w-auto sm:hidden" 
                size="txtInterRegular16"
              >
                Popular Location
              </Text>
            </div>
            <div className="flex flex-row gap-[5px] items-center justify-center w-auto">
              <Img
                className="h-3 w-[17px]"
                src="/images/img_mail.svg"
                alt="mail"
              />
              <Text
                className="text-base text-white-A700 w-auto sm:hidden  md:hidden"
                size="txtInterRegular16"
              >
                admin@velvetklub.com
              </Text>
            </div>
          </div> */}
            </div>

            <div className="flex flex-row  gap-[33px] items-start justify-start w-auto sm:gap-[5px]">
              {props.isLogging ? (
                <>
                  {/* <div className="font-inter font-semibold sm:px-5 text-left text-lg text-white-A700 mt-3">
                Welcome {displayName}
              </div> */}
                  {/* <Link to="/myprofilesidebaar">
                {" "}
               
                <Button
                  className="bg-pink-500 cursor-pointer flex items-center justify-center custom8000:w-[35px] custom8000:px-[0px]  custom8000:py-[4px] w-[130px] px-[30px] py-[10px] rounded-[5px]"
                  leftIcon={
                    <Img
                      className="h-6 mb-px mr-[5px] custom8000:mr-0"
                      src="/images/img_profile.svg"
                      alt="profile"
                    />
                  }
                >
                  <div className="font-inter font-semibold sm:px-5 text-left text-lg text-white-A700 custom8000:hidden">
                    Profile
                  </div>
                </Button>
              </Link> */}
                <div className="sm:hidden colorChangeHover"  >
                      
                      <Button
                     onClick={() => handleCity()}
                        className=" cursor-pointer flex items-center justify-center w-[80px] custom8000:w-[35px]  custom8000:py-[4px]  py-[5px] rounded-[5px]"
                        leftIcon={
                          <Img
                            className="h-5 mb-px mr-[5px] custom8000:mr-0"
                            src="/images/homess.png"
                            alt="profile"
                          />
                        }
                      >
                        <div className=" custom8000:hidden sm:px-5 text-left text-lg text-white-A700">
                        Home
                        </div>
                      </Button>
                      </div>
        
                      <Link className="sm:hidden colorChangeHover" to="/blog">
                      <Button
                        className=" cursor-pointer flex items-center justify-center w-[80px] custom8000:w-[35px]  custom8000:py-[4px]  py-[5px] rounded-[5px]"
                        leftIcon={
                          <Img
                            className="h-5 mb-px mr-[5px] custom8000:mr-0"
                            src="/images/blogss.png"
                            alt="profile"
                          />
                        }
                      >
                        <div className="  custom8000:hidden sm:px-5 text-left text-lg text-white-A700">
                        Blog
                        </div>
                      </Button>
                      </Link>
                         
        
                      <div 
                              onClick={() => {
                                setIsOpen(!isOpen);
                              }}
                              className="  md:mt-0"
                            >
                              <Button
                            
                                className="text-[17px]   flex px-1 py-1.5 text-white-A700 w-auto   rounded-[5px] colorChangeHover"
                              >
                                <Img
                                  className="h-[25px] mr-[6px] "
                                  src="/images/img_options.svg"
                                  alt="options"
                                />
                                <div className="mt-[3px] custom8000:hidden">Popular Locations</div>
                              </Button>
                            </div>

                  <Link
                    to={
                      props.role === "model"
                        ? "/modelDashboard"
                        : props.role === "admin"
                        ? "/AdminDashboard/dashboard"
                        : "/not Found"
                    }
                  >
                    {" "}
                    <Button
                      className="bg-pink-500 cursor-pointer flex items-center justify-center w-[125px]     py-[5px] rounded-[5px]"
                      leftIcon={
                        <Img
                          className="h-6 mb-px mr-[5px] "
                          src="/images/img_profile.svg"
                          alt="profile"
                        />
                      }
                    >
                      <div className="font-inter font-semibold text-left text-lg text-white-A700 ">
                        Dashboard
                      </div>
                    </Button>
                  </Link>

                  {/* <Link to={props.role==="model"?"/modelDashboard":props.role==="admin"?"/AdminDashboard/dashboard":"/not Found"} >
                {" "}
              
                <Button
                  className="bg-pink-500 cursor-pointer flex items-center justify-center min-w-[130px]   px-[30px] py-[10px] rounded-[5px]"
                 
                >
                  <div className="font-inter font-semibold sm:px-5 text-left text-lg text-white-A700">
                  Dashboard
                  </div>
                </Button>
              </Link> */}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={Logout}
                    className="font-inter  font-semibold sm:px-3 text-left text-lg text-white-A700 mt-1 "
                  >
                    Logout
                  </div>
                </>
              ) : (
                <div  className="flex flex-row gap-[10px] sm:gap-[10px] items-start justify-start w-auto">
                    <Link className="sm:hidden colorChangeHover"  to="/">
                      
              <Button
              
                className=" cursor-pointer flex items-center justify-center w-[80px] custom8000:w-[35px]  custom8000:py-[4px]  py-[5px] rounded-[5px]"
                leftIcon={
                  <Img
                    className="h-5 mb-px mr-[5px] custom8000:mr-0"
                    src="/images/homess.png"
                    alt="profile"
                  />
                }
              >
                <div className=" custom8000:hidden sm:px-5 text-left text-lg text-white-A700">
                Home
                </div>
              </Button>
              </Link>

              <Link className="sm:hidden colorChangeHover" to="/blog">
              <Button
                className=" cursor-pointer flex items-center justify-center w-[80px] custom8000:w-[35px]  custom8000:py-[4px]  py-[5px] rounded-[5px]"
                leftIcon={
                  <Img
                    className="h-5 mb-px mr-[5px] custom8000:mr-0"
                    src="/images/blogss.png"
                    alt="profile"
                  />
                }
              >
                <div className="  custom8000:hidden sm:px-5 text-left text-lg text-white-A700">
                Blog
                </div>
              </Button>
              </Link>
                 

              <div 
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                      className="  md:mt-0"
                    >
                      <Button
                    
                        className="text-[17px]   flex px-1 py-1.5 text-white-A700 w-auto   rounded-[5px] colorChangeHover sm:hidden"
                      >
                        <Img
                          className="h-[25px] mr-[6px] "
                          src="/images/img_options.svg"
                          alt="options"
                        />
                        <div className="mt-[3px] custom8000:hidden">Popular Locations</div>
                      </Button>
                    </div>

<Link className="colorChangeHoverWhite" to="/registration ">
              <Button
              
                className=" sm:border cursor-pointer flex items-center justify-center w-[110px]   sm:w-[85px] custom8000:py-[4px] py-[5px] rounded-[5px] colorChangeHover"
                leftIcon={
                  <Img
                    className="h-6 mb-px mr-[8px] custom8000:mr-0"
                    src="/images/Grouppl2.png"
                    alt="profile"
                  />
                }
              >
                <div  className="font-inter font-semibold sm:px-5 text-left text-lg text-white-A700  sm:hidden ">
                   Join Us
                </div>
                <div className="font-inter font-semibold  text-left text-lg text-white-A700 hidden sm:block sm:ml-1">
                   Join
                </div>
              </Button>
              </Link>

               
              <Link className="colorChangeHoverWhite" to="/login ">
              <Button
                className="  bg-pink-500 cursor-pointer flex items-center justify-center w-[110px] sm:w-[85px]  custom8000:py-[4px]  py-[5px] rounded-[5px]"
                leftIcon={
                  <Img
                    className="h-6 mb-px mr-[5px] custom8000:mr-0"
                    src="/images/img_profile.svg"
                    alt="profile"
                  />
                } >
                <div className="font-inter font-semibold    text-left text-lg text-white-A700">
                 Sign in
                </div>
              </Button>
              </Link>
                </div>
              )}
            </div>
          </header>
        </div>
        <Line className="bg-white-A700_4c h-px w-full mt-[-10px]" />

   <div className="bg-black-900 pt-2" style={{display:"flex" , justifyContent:"center",marginTop:"0px"}}>
   <div style={{overflow:"hidden"  , width:"94%" }} className="flex   flex-row   gap-[27px] md:w-full md:gap-[15px] mb-[20px] sm:gap-[10px]  custom8000:hidden ">
          <Text
            className="text-[19px] text-pink-500 "
            size="txtInterBold18Pink500"
          >
          <div  className="mt-[6px]" style={{width:"140px" ,  color: "rgb(255, 255, 255)" , fontWeight:"600"}}>
          Browse By City
          </div>
          </Text>

          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {
              <NavLink to="/escorts/south-australia/adelaide">
                Adelaide{" "}
              </NavLink>
            }
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {<NavLink to="/escorts/queensland/brisbane"> Brisbane </NavLink>}
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {
              <NavLink to="/escorts/australian-capital-territory/canberra">
                {" "}
                Canberra{" "}
              </NavLink>
            }
          </Text>
          <Text  className="text-base text-white-A700   hover:text-pink-500 cursor-pointer mt-[4px]">
            {
              <NavLink  to="/escorts/queensland/gold-coast">
                {" "}
              <div  className="mt-1" style={{width:"90px"}}>   <NavLink  to="/escorts/queensland/gold-coast"> Gold Coast</NavLink> </div>{" "}
              </NavLink>
            }
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {<NavLink to="/escorts/victoria/melbourne"> Melbourne </NavLink>}
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {<NavLink to="/escorts/western-australia/perth"> Perth </NavLink>}
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {
              <NavLink to="/escorts/queensland/sunshine-coast">
                {" "}
               <div className="mt-1" style={{width:"135px"  }}> <NavLink to="/escorts/queensland/sunshine-coast"> Sunshine Coast</NavLink> </div>{" "}
              </NavLink>
            }
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {<NavLink to="/escorts/new-south-wales/sydney"> Sydney </NavLink>}
          </Text>

          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {
              <NavLink to="/escorts/new-south-wales/parramatta">
                {" "}
                Parramatta{" "}
              </NavLink>
            }
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {
              <NavLink to="/escorts/new-south-wales/newcastle">
                {" "}
                Newcastle{" "}
              </NavLink>
            }
          </Text>
          <Text
            o
            className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]"
          >
            {<NavLink to="/escorts/victoria/geelong"> Geelong </NavLink>}
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {
              <NavLink to="/escorts/northern-territory/darwin">
                {" "}
                Darwin {" "}
              </NavLink>
            }
          </Text>
          <Text className="text-base text-white-A700 w-auto  hover:text-pink-500 cursor-pointer mt-[4px]">
            {<NavLink to="/escorts/tasmania/hobart"> Hobart </NavLink>}
          </Text>
        </div>



   </div>
   <Line className="bg-white-A700_4c h-px w-full custom8000:hidden" />
      </div>
      
    </>
  );
};

Header.defaultProps = {};

const mapStateToProps = (state) => ({
  userName: state.user.userName,
  isLogging: state.user.isLogging,
  role: state.user.role,
});

// export default Header;
export default connect(mapStateToProps)(Header);
